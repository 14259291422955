:root {
  --primary: #FEA116;
  --light: #F1F8FF;
  --dark: #0F172B;
}

.reduced-height {
  height: 500px; /* Adjust the height as needed */
}

/* Parent container */
.corporation.content.flex.mtop {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.box.shadoww {
  flex: 1 1 200px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin: 10px;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box.shadoww img {
  width: 100%;
  height: auto;
  display: block;
  max-width: 150px;
}

.details-button {
  display: inline-block;
  padding: 10px 40px;
  margin-top: 10px;
  font-size: 0.9rem;
  color: #fff;
  background-color: #086170;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.details-button:hover {
  background-color: #e68900;
}

.space-left {
  margin-left: 10px; /* Adjust as needed */
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.box {
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.container-xxl {
  padding: 2.5rem 0;
}

.container {
  max-width: 1140px;
  margin: 0 auto;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .content {
      grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .content {
      grid-template-columns: 1fr;
  }

  .details-button {
      padding: 8px 30px;
      font-size: 0.8rem;
  }
}

  
  .testimonial-card {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    margin: 0 1rem;
    border-radius: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .testimonial-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .testimonial-img {
    width: 160px;
    height: 160px;
    object-cover: cover;
  }
  
  
  .img img {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .text {
    padding: 20px;
  }
  
  .category {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .category span {
    background: #ff98001a;
    color: #ff9800;
    padding: 5px 10px;
    border-radius: 5px;
  }
  
  .category .fa-heart {
    color: #ccc;
    cursor: pointer;
  }
  
  .category .fa-heart:hover {
    color: #ff9800;
  }
  
  h4 {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  /* p {
    font-size: 0.9rem;
    color: #555;
  } */
  
  .fa-location-dot {
    margin-right: 5px;
    color: #ccc;
  }
  

  .language-selector {
    position: relative;
    width: 150px;
  }
  
  .selected-language {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: #2d2d5b;
    border-radius: 10px;
    cursor: pointer;
    color: white;
  }
  
  .selected-language img {
    width: 30px;
    height: 20px;
    margin-right: 10px;
  }
  
  .language-options {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #2d2d5b;
    border-radius: 10px;
    margin-top: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 10;
  }
  
  .language-option {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    color: white;
  }
  
  .language-option img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  
  .language-option:hover {
    background-color: #444477;
  }
  
  

  
.fw-medium {
    font-weight: 500 !important;
}

.fw-semi-bold {
    font-weight: 600 !important;
}

.back-to-top {
    position: fixed;
    display: none;
    right: 45px;
    bottom: 45px;
    z-index: 99;
}


/*** Spinner ***/
#spinner {
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s ease-out, visibility 0s linear .5s;
    z-index: 99999;
}

#spinner.show {
    transition: opacity .5s ease-out, visibility 0s linear 0s;
    visibility: visible;
    opacity: 1;
}


/*** Button ***/
.btn {
    font-weight: 500;
    text-transform: uppercase;
    transition: .5s;
}

.btn.btn-primary,
.btn.btn-secondary {
    color: #FFFFFF;
}

.btn-square {
    width: 38px;
    height: 38px;
}

.btn-sm-square {
    width: 32px;
    height: 32px;
}

.btn-lg-square {
    width: 48px;
    height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    border-radius: 2px;
}


/*** Navbar ***/
.navbar-dark .navbar-nav .nav-link {
    margin-right: 30px;
    padding: 25px 0;
    color: #FFFFFF;
    font-size: 15px;
    text-transform: uppercase;
    outline: none;
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active {
    color: var(--primary);
}
